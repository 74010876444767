import React from "react";
import styles from "../../styles/Home/Blog.module.css";

import uk from "../../assets/images/UK.jpg";
import can from "../../assets/images/toronto.jpg";
import ger from "../../assets/images/ger.jpg";
import dub from "../../assets/images/dub.jpg";
import sa from "../../assets/images/sa.jpg";
import sing from "../../assets/images/sing.jpg";

function Blog() {
  const blogPosts = [
    {
      id: 1,
      title: "UK",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: uk,
      url: "#",
    },
    {
      id: 2,
      title: "Canada",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: can,
      url: "#",
    },
    {
      id: 3,
      title: "Dubai",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: dub,
      url: "#",
    },
    {
      id: 4,
      title: "Singapore",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: sing,
      url: "#",
    },
    {
      id: 5,
      title: "Germany",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: ger,
      url: "#",
    },
    {
      id: 6,
      title: "Saudi Arabia",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: sa,
      url: "#",
    },
  ];

  const shuffledBlogPosts = blogPosts.sort(() => Math.random() - 0.5);

  return (
    <section className={styles.blog} id="blog">
      <div className="container">
        <h2 className={styles.blogTitle}>INTERNATIONAL OPPORTUNITIES</h2>
        <h4 className={styles.blogSubTitle}>Crackerjack has helped clients traverse the globe</h4>
        <div className={styles.blogPosts}>
          {shuffledBlogPosts.map((post) => (
            <div key={post.id} className={styles.blogCard}>
              <img
                src={post.image}
                alt={post.title}
                className={styles.blogImage}
              />
              <a href={post.url} className={styles.blogPostTitle}>
                {post.title}
              </a>
              {/* <p className={styles.blogPostContent}> {post.content} </p> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blog;
