import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styles from "../../styles/Home/Testimonials.module.css";

import profile from "../../assets/images/anonymous.jpg";
// import client2Image from "../../assets/images/client2.png";
// import client3Image from "../../assets/images/client3.jpg";

const testimonialsData = [
  {
    id: 1,
    author: "Mr. Mohanlal",
    text: "“The amount of patience and care you showed while following up with me to get me on board was really commendable and appreciable. Also, the efforts you put in with the job suggestions were really appreciable. Thanks, and keep it up.”",
    image: profile,
  },
  {
    id: 2,
    author: "Mr. Kunte",
    text: "“I have had 3 sessions with you and just wanted to mention that they are very helpful! Thanks to the holistic approach, I have received input on career tracks, my CV, the intro, positioning, and more. Also, the suggestions on unpublished markets and exploring alternatives together at this stage of my career were very helpful.”",
    image: profile,
  },
  {
    id: 3,
    author: "Mr. Gupta",
    text: "“Thank you so much for the guidance and direction in making a resume, helping with the non-technical interview process, and providing tech and non-tech resources for getting interview calls. Your every coaching session is very helpful in continuing to job search and avoiding mistakes during interviews.”",
    image: profile,
  },
];

function Testimonials() {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonialIndex(
      (prevIndex) => (prevIndex + 1) % testimonialsData.length
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonialIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTestimonialIndex]);

  return (
    <section className={styles.testimonials} id="testimonial">
      <div className="container">
        <h2 className={styles.testimonialsTitle}>Testimonials</h2>
        <div className={styles.testimonial}>
          <button
            className={styles.testimonialButton}
            onClick={prevTestimonial}
          >
            <FaChevronLeft />
          </button>
          <div className={styles.testimonialContent}>
            {/* <div className={styles.testimonialImage}>
              <img
                src={testimonialsData[currentTestimonialIndex].image}
                alt={testimonialsData[currentTestimonialIndex].author}
              />
            </div> */}
            <p className={styles.testimonialText}>
              {testimonialsData[currentTestimonialIndex].text}
            </p>
            <p className={styles.testimonialAuthor}>
              - {testimonialsData[currentTestimonialIndex].author}
            </p>
          </div>
          <button
            className={styles.testimonialButton}
            onClick={nextTestimonial}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
