import HomePage from "./pages/HomePage";
import './styles/global.css';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
