import React, { useState } from "react";
import styles from "../../styles/Home/FAQ.module.css";

function FAQ() {
  const [faqs, setFaqs] = useState([
    {
      question: "Is ATS compatibility important for a resume?",
      answer:
        "Yes. ATS compatibility refers to the ability of your resume to be parsed and interpreted correctly by applicant tracking systems. An ATS scans your resume for keywords, relevant experience, and qualifications to determine if you're a good match for the job. If your resume isn't formatted correctly or lacks the necessary keywords, it may be rejected by the ATS before it reaches a human recruiter.",
      isOpen: false,
    },
    {
      question: "Can you write me a cover letter?",
      answer:
        "Yes, this service is available with us. Cover letters are important in many job application processes. while some job postings may not explicitly require a cover letter, submitting one can still greatly benefit your application.",
      isOpen: false,
    },
    {
      question: "What are your prices?",
      answer: "Crackerjack offers different pricing packages, which vary according to your demands, industry, and experience. To learn more in detail, you will have to reach out to us personally.",
      isOpen: true,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index
          ? { ...faq, isOpen: !faq.isOpen }
          : { ...faq, isOpen: false }
      )
    );
  };

  return (
    <div className={styles.faq} id="faq">
      <div className={styles.faqContainer}>
        <h2 className={styles.faqTitle}>Frequently Asked Questions</h2>
        <div className={styles.faqList}>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`${styles.faqItem} ${faq.isOpen ? styles.open : ""}`}
            >
              <div
                className={styles.faqQuestion}
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <div className={styles.faqIcon}>{faq.isOpen ? "-" : "+"}</div>
              </div>
              <div className={styles.faqAnswer}>{faq.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
